import {
  Component,
  HostListener,
  Inject,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "../core/window.service";
import ROUTES from "src/app/static/app-routes";
import MainPageSubViews from "src/app/static/app-sub-views";
import { ElementRefHandlerService } from "src/app/services/element-ref-handler.service";
import { NavBarColorService } from "src/app/services/nav-bar-color.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {
  @Output() scrollToView = new EventEmitter<number>();
  @ViewChild("header") public headerElementRef: ElementRef;
  isCollapsed = true;
  navBarStyle = "navbar navbar-expand-lg navbar-hide";
  isScrolledTop = false;
  navBarTogglerStyle = "animated-icon4";
  public _ROUTES = ROUTES;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    public elementRefHandlerService: ElementRefHandlerService,
    public navBarColorService: NavBarColorService
  ) {}

  ngAfterViewInit() {
    this.elementRefHandlerService.headerElementRef = this.headerElementRef;
  }

  togglerOnClickHandler(): any {
    this.isCollapsed = !this.isCollapsed;
    this.navBarTogglerStyle = this.isCollapsed
      ? "animated-icon4"
      : "animated-icon4 open";
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;

    if (number > 100) {
      this.navBarStyle = "navbar navbar-expand-lg navbar-show";
    } else {
      this.navBarStyle = "navbar navbar-expand-lg navbar-hide";
    }
  }

  public scrollToHome(): void {
    this.elementRefHandlerService.moveToSpecificView(MainPageSubViews.HEADER);
  }
}

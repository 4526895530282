<div class="contact-container">
  <div class="container">
    <div class="row">
      <div class="col-md-8 section-header">
        <h2 class="contact-title">Contact</h2>
        <p class="contact-description">
          Having questions? Dont hesitate to leave a message!
        </p>
      </div>
    </div>
    <div *ngIf="!isEmailSent">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              placeholder="Name *"
              [value]="name"
              (input)="name = $event.target.value"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="text"
              placeholder="Email *"
              [value]="contactEmail"
              (input)="contactEmail = $event.target.value"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <textarea
              class="form-control"
              placeholder="Your message *"
              [value]="message"
              (input)="message = $event.target.value"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="submit-button-container">
            <button
              [ngClass]="{
                'ml-auto mr-auto btn btn-primary text-xs-center disabled':
                  sendingEmail,
                'ml-auto mr-auto btn btn-primary text-xs-center': !sendingEmail
              }"
              (click)="_submitContactEmail()"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isEmailSent">
      <h2 class="message-received-succesfully">
        Message received successfully, Thank you for contacting!
      </h2>
    </div>
  </div>
</div>

<div class="portfolio-container">
  <div class="container">
    <div class="row">
      <div class="col-md-8 section-header">
        <h2 class="portfolio-title">Portfolio</h2>
        <p class="portfolio-description">
          Having programmed from neural network algorithms to Android meditation
          timer application, there is quite a lot of projects and programs that
          have never seen the daylight. Below are few of the projects that I
          have done in my spare time for learning purposes.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4" *ngFor="let item of portfolio_cards">
        <div class="portfolio-section">
          <a routerLink="{{ item.path }}">
            <div class="portfolio-card">
              <div
                class="porftolio-object-upper-container {{ item.background }}"
              ></div>
              <div class="porfolio-object-bottom-container">
                <div class="portfolio-description-container">
                  <h4 class="portfolio-object-title">{{ item.title }}</h4>
                  <hr />
                  <p class="portfolio-object-text">{{ item.text }}</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

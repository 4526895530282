import { Component, OnInit } from "@angular/core";
import ROUTES from "../../../static/app-routes";

@Component({
  selector: "app-portfolio",
  templateUrl: "./portfolio.component.html",
  styleUrls: ["./portfolio.component.scss"],
})
export class PortfolioComponent implements OnInit {
  portfolio_cards = [
    {
      background: "neuralnetwork-background",
      title: "Neural Network",
      text: "Make query to neural network with Finnish city latitude and longitude data and see what neural network gives for response.",
      path: ROUTES.NEURAL_NETWORK,
    },
    {
      background: "memegenerator-background",
      title: "Meme Generator",
      text: "Who doesnt love memes. They are the building blocks of 2018 internet humour. See how canvas element is used to create a meme with an editor.",
      path: ROUTES.MEME_GENERATOR,
    },
    {
      background: "meditation-app-background",
      title: "Meditation App",
      text: "Mobile app designed to make meditation tracking easier. Includes meditation timer and stores meditation sessions to Android's SQLite database.",
      path: ROUTES.MEDITATION_APP,
    },
  ];

  constructor() {}

  ngOnInit() {}
}

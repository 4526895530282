import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FrontpageComponent } from "./components/frontpage/frontpage.component";
import { NeuralnetworkComponent } from "./components/projects/neuralnetwork/neuralnetwork.component";
import { MemegeneratorComponent } from "./components/projects/memegenerator/memegenerator.component";
import { MeditationAppComponent } from "./components/projects/meditation-app/meditation-app.component";
import { RussianStrengthProgramGeneratorComponent } from "./components/training/russian-strength-program-generator/russian-strength-program-generator.component";
import ROUTES from "./static/app-routes";

const routes: Routes = [
  { path: ROUTES.ROOT, component: FrontpageComponent },
  { path: ROUTES.NEURAL_NETWORK, component: NeuralnetworkComponent },
  { path: ROUTES.MEME_GENERATOR, component: MemegeneratorComponent },
  { path: ROUTES.MEDITATION_APP, component: MeditationAppComponent },
  {
    path: ROUTES.STRENGTH_TRAINING,
    component: RussianStrengthProgramGeneratorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

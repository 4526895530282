import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import MainPageSubViews from "src/app/static/app-sub-views";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Output() scrollToView = new EventEmitter<number>();
  madeWithDescription = "Bootstrap";
  madeWithClass = "hide-madewith";
  enableArrow = false;
  madewith_list = [
    {
      id: 0,
      url: "../../../assets/icons/angular-icon-1.svg",
      description: "Angular 11",
    },
    {
      id: 1,
      url: "../../../assets/icons/bootstrap.png",
      description: "Bootstrap 4",
    },
    {
      id: 2,
      url: "../../../assets/icons/nginx.png",
      description: "Nginx",
    },
    {
      id: 3,
      url: "../../../assets/icons/dotnet.png",
      description: ".Net Core 5",
    },
    {
      id: 4,
      url: "../../../assets/icons/scss.svg",
      description: "Sassy Cascading Style Sheets",
    },
    {
      id: 5,
      url: "../../../assets/icons/aws.png",
      description: "AWS Cloud",
    },
    {
      id: 6,
      url: "../../../assets/icons/docker.png",
      description: "Docker",
    },
    {
      id: 7,
      url: "../../../assets/icons/certbot.svg",
      description: "Certbot",
    },
  ];

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.enableArrow = true;
    }, 8000);
  }

  showMadeWithNameById(id) {
    this.madeWithDescription = this.madewith_list[id].description;
    this.madeWithClass = "show-madewith";
  }

  hideMadeWithName() {
    this.madeWithClass = "hide-madewith";
  }

  clickArrow() {
    this.scrollToView.emit(MainPageSubViews.INTERESTS);
  }
}

<div>
  <app-header (scrollToView)="scrollToView($event)"></app-header>
</div>
<div #interests>
  <app-interests (scrollToView)="scrollToView($event)"></app-interests>
</div>
<div #portfolio>
  <app-portfolio (scrollToView)="scrollToView($event)"></app-portfolio>
</div>
<div #about>
  <app-about #about (scrollToView)="scrollToView($event)"></app-about>
</div>
<div #contact>
  <app-contact (scrollToView)="scrollToView($event)"></app-contact>
</div>
<app-footer></app-footer>

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
} from "@angular/core";

import { DOCUMENT } from "@angular/common";
import { WINDOW } from "../../core/window.service";

@Component({
  selector: "app-memegenerator",
  templateUrl: "./memegenerator.component.html",
  styleUrls: ["./memegenerator.component.scss"],
})
export class MemegeneratorComponent implements OnInit {
  // Reference to canvas element
  @ViewChild("canvas", { static: true }) canvas: ElementRef;
  @ViewChild("img", { static: true }) img: ElementRef;
  @ViewChild("memegenContainer", { static: true }) container: ElementRef;

  viewLoaded = false;

  // Canvas editor variables
  offsetTopContainer = 0;
  offsetLeftContainer = 15;

  textYMin = 200;
  textYMax = 900;
  scale = 1;
  rotation = 0;

  backgroundXPos = 0;
  backgroundYPos = 0;
  backgroundPrevX = 0;
  backgroundPrevY = 0;

  mousePosX = 0;
  mousePosY = 0;

  distanceX = 0;
  distanceY = 0;

  mouseHit = null;
  mouseHoverHit = null;
  file = null;

  texts = [
    {
      value: "",
      x: 0,
      y: 0,
      fontSize: 80,
      width: 0,
      height: 20,
    },
    {
      value: "",
      x: 0,
      y: 0,
      fontSize: 70,
      width: 0,
      height: 20,
    },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.texts[0].x = this.canvas.nativeElement.width / 2;
    this.texts[0].y = this.textYMin;
    this.texts[1].x = this.canvas.nativeElement.width / 2;
    this.texts[1].y = this.textYMax;
  }

  applyTransform() {
    let canvas = this.canvas.nativeElement;
    let ctx = this.canvas.nativeElement.getContext("2d");
    let img = this.img.nativeElement;

    // Save the drawing state into canvas stack
    ctx.save();

    // Erase previously drawn image from the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Translate to center of the canvas
    ctx.translate(
      canvas.width / 2 - this.backgroundXPos,
      canvas.height / 2 - this.backgroundYPos
    );

    // Scale the background image with given parameters
    ctx.scale(this.scale, this.scale);

    // Rotate background image with radians values
    ctx.rotate((this.rotation * Math.PI) / 180);

    // Reverse the previous translate
    ctx.translate(
      -canvas.width / 2 - this.backgroundXPos,
      -canvas.height / 2 - this.backgroundYPos
    );

    let x = canvas.width / 2 - img.width / 2;
    let y = canvas.height / 2 - img.height / 2;
    // Draw the image
    ctx.drawImage(img, x, y);
    // Restore the drawing stack
    ctx.restore();
  }

  /*
   * Text changes rendering
   */
  applyText() {
    let canvas = this.canvas.nativeElement;
    let ctx = canvas.getContext("2d");

    ctx.strokeStyle = "black";
    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.lineJoin = "round";
    ctx.lineWidth = 8;

    let texts = this.texts;

    for (let i = 0; i < texts.length; i++) {
      ctx.beginPath();
      ctx.font = texts[i].fontSize + "pt Impact";

      ctx.strokeText(texts[i].value, texts[i].x, texts[i].y);

      ctx.fillText(texts[i].value, texts[i].x, texts[i].y);
    }

    // if(this.mouseHit == 0 || this.mouseHit == 1){
    // 	ctx.beginPath();
    // 	ctx.rect(
    // 			this.normaliseValue(this.mousePosXOriginal,this.canvas.nativeElement.scrollWidth,0,this.canvas.nativeElement.width,0) - this.texts[0].width / 2,
    // 			this.normaliseValue(this.mousePosYOriginal,this.canvas.nativeElement.scrollHeight,0,this.canvas.nativeElement.height,0) - this.texts[0].height/1.5,
    // 			this.texts[0].width * 1.4,
    // 			this.texts[0].height * 1.4);
    // 	ctx.stroke();
    // }
  }

  renderCanvas() {
    this.applyTransform();
    this.applyText();
  }

  _textSizeHandler() {
    let ctx = this.canvas.nativeElement.getContext("2d");

    for (let i = 0; i < this.texts.length; i++) {
      this.texts[i].width = parseInt(
        ctx.measureText(this.texts[i].value).width
      );
      this.texts[i].height = this.texts[i].fontSize;
    }
    this.renderCanvas();
  }

  /*
   * Pre: User has pressed mouse button 1
   * Past: Pointer cordinate has been calculated & collision detection on canvas texts
   */
  _onMouseDown(event) {
    let viewContainer = this.container.nativeElement;

    // How far is the canvas element from its parent eleement
    let offsetLeft = viewContainer.offsetLeft;
    let offsetTop = viewContainer.offsetTop;

    // Get the exact mouse x & y positions with views and window offset values
    let mouseX =
      event.clientX -
      offsetLeft -
      this.offsetLeftContainer +
      window.pageXOffset;
    let mouseY =
      event.clientY - offsetTop - this.offsetTopContainer + window.pageYOffset;

    this.mousePosX = this.normaliseValue(
      mouseX,
      this.canvas.nativeElement.scrollWidth,
      0,
      this.canvas.nativeElement.width,
      0
    );
    this.mousePosY = this.normaliseValue(
      mouseY,
      this.canvas.nativeElement.scrollHeight,
      0,
      this.canvas.nativeElement.height,
      0
    );

    /*
     * Detect Mouse1 collision on canvas texts every mousepress
     */
    for (let i = 0; i < this.texts.length; i++) {
      if (this.detectCollision(i)) {
        this.mouseHit = i;
        this.distanceX = this.mousePosX - this.texts[i].x;
        this.distanceY = this.mousePosY - this.texts[i].y;
      }
    }

    this.backgroundPrevX =
      this.normaliseValue(
        this.mousePosX,
        this.canvas.nativeElement.width,
        0,
        -400,
        400
      ) - this.backgroundXPos;
    this.backgroundPrevY =
      this.normaliseValue(
        this.mousePosY,
        this.canvas.nativeElement.height,
        0,
        -400,
        400
      ) - this.backgroundYPos;

    if (this.mouseHit == null) {
      this.mouseHit = 3;
    }
  }
  /*
   * Returns true if mouse1 click hits on texts calculated bounds otherwise false
   */
  detectCollision(index) {
    let text = this.texts[index];

    return (
      this.mousePosX >= text.x - text.width &&
      this.mousePosX <= text.x + text.width &&
      this.mousePosY >= text.y - text.height &&
      this.mousePosY <= text.y + text.height
    );
  }

  _onMouseMove(event) {
    event.preventDefault();

    if (this.detectCollision(0) || this.detectCollision(1)) {
      this.canvas.nativeElement.style.cursor = "grab";
    }

    if (!this.detectCollision(0) && !this.detectCollision(1)) {
      this.canvas.nativeElement.style.cursor = "default";
    }

    let viewContainer = this.container.nativeElement;
    let offsetLeft = viewContainer.offsetLeft;
    let offsetTop = viewContainer.offsetTop;

    let mouseX =
      event.clientX -
      offsetLeft -
      this.offsetLeftContainer +
      window.pageXOffset;
    let mouseY =
      event.clientY - offsetTop - this.offsetTopContainer + window.pageYOffset;

    this.mousePosX = this.normaliseValue(
      mouseX,
      this.canvas.nativeElement.scrollWidth,
      0,
      this.canvas.nativeElement.width,
      0
    );
    this.mousePosY = this.normaliseValue(
      mouseY,
      this.canvas.nativeElement.scrollHeight,
      0,
      this.canvas.nativeElement.height,
      0
    );

    if (this.mouseHit == null) {
      return;
    }

    if (this.mouseHit != 3) {
      this.texts[this.mouseHit].x = this.mousePosX - this.distanceX;
      this.texts[this.mouseHit].y = this.mousePosY - this.distanceY;
    } else {
      this.backgroundXPos =
        this.normaliseValue(
          this.mousePosX,
          this.canvas.nativeElement.width,
          0,
          -400,
          400
        ) - this.backgroundPrevX;
      this.backgroundYPos =
        this.normaliseValue(
          this.mousePosY,
          this.canvas.nativeElement.height,
          0,
          -400,
          400
        ) - this.backgroundPrevY;
    }

    this.renderCanvas();
  }

  _onMouseUp(event) {
    this.mouseHit = null;
    let canvas = this.canvas.nativeElement;
    canvas.style.cursor = "default";
    this.renderCanvas();
  }

  /*
   * Load new image as background to canvas
   */
  _fileHandler(event) {
    let img = this.img.nativeElement;
    let reader = new FileReader();

    reader.onload = () => {
      img.onload = () => {
        this.renderCanvas();
        this.viewLoaded = true;
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  /*
   * Load new image as background to canvas
   */
  _fileHandlerDrop(event) {
    event.preventDefault();

    let img = this.img.nativeElement;
    let reader = new FileReader();

    reader.onload = () => {
      img.onload = () => {
        this.viewLoaded = true;
        this.renderCanvas();
      };
      img.src = reader.result;
    };
    if (event.dataTransfer.files[0])
      reader.readAsDataURL(event.dataTransfer.files[0]);
  }

  /*
   * Download button click handler
   */
  _downloadHandler(event) {
    let canvas = this.canvas.nativeElement;
    let savedfileName = "dankMeme.png";

    if (!/Edge\/\d./i.test(navigator.userAgent)) {
      canvas.toBlob((blop) => {
        let reader = new FileReader();
        reader.readAsDataURL(blop);

        reader.onload = (event) => {
          let save = document.createElement("a");
          save.href = event.currentTarget["result"];
          save.target = "";
          save.download = savedfileName;

          document.body.appendChild(save);
          save.click();
          document.body.removeChild(save);
          window.URL || window["webkitURL"].revokeObjectURL(save.href);
        };
      });
    } else {
      let blobObject = canvas.msToBlob();
      window.navigator.msSaveBlob(blobObject, savedfileName);
    }
  }

  /*
   * Center The text on X axis
   */
  _centerTextHandler(event) {
    // Set default values for text position
    this.texts[0].x = this.canvas.nativeElement.width / 2;
    this.texts[1].x = this.canvas.nativeElement.width / 2;
    this.renderCanvas();
  }

  _clearCanvas(event) {
    (this.texts[0].value = ""),
      (this.texts[0].x = this.canvas.nativeElement.width / 2);
    this.texts[0].y = this.textYMin;
    (this.texts[1].value = ""),
      (this.texts[1].x = this.canvas.nativeElement.width / 2);
    this.texts[1].y = this.textYMax;

    this.viewLoaded = false;

    this.canvas.nativeElement
      .getContext("2d")
      .clearRect(
        0,
        0,
        this.canvas.nativeElement.width,
        this.canvas.nativeElement.height
      );
  }

  _upperFontHandler(value) {
    let ctx = this.canvas.nativeElement.getContext("2d");
    this.texts[0].fontSize = value;
    this.texts[0].width = parseInt(ctx.measureText(this.texts[0].value).width);
    this.texts[0].height = this.texts[0].fontSize;
    this.renderCanvas();
  }

  _lowerFontHandler(value) {
    let ctx = this.canvas.nativeElement.getContext("2d");
    this.texts[1].fontSize = value;
    this.texts[1].width = parseInt(ctx.measureText(this.texts[1].value).width);
    this.texts[1].height = this.texts[1].fontSize;
    this.renderCanvas();
  }

  _backGroundScaleHandler(value) {
    this.scale = value;
    this.renderCanvas();
  }
  _backGroundXPosition(value) {
    this.backgroundXPos = value;
    this.renderCanvas();
  }
  _backGroundYPosition(value) {
    this.backgroundYPos = value;
    this.renderCanvas();
  }
  _backGroundRotation(value) {
    this.rotation = value;
    this.renderCanvas();
  }

  _dragOver(event) {
    event.preventDefault();
  }

  normaliseValue(inputValue, maxValue, minValue, maxRange, minRange) {
    return (
      ((inputValue - (minValue - 0.01)) * (maxRange - minRange)) /
        (maxValue - 0.01 - (minValue - 0.01)) +
      minRange
    );
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-russian-strength-program-generator",
  templateUrl: "./russian-strength-program-generator.component.html",
  styleUrls: ["./russian-strength-program-generator.component.scss"],
})
export class RussianStrengthProgramGeneratorComponent implements OnInit {
  public STORAGE_WEIGHT_KEY: string = "max1rep";
  public weeks: number = 9;
  public weightProgramRows: WeightProgramRow[] = [];
  public maxOneRep: number;
  constructor() {}

  ngOnInit() {
    let maxRep = JSON.parse(localStorage.getItem(this.STORAGE_WEIGHT_KEY));

    if (!maxRep) {
      maxRep = 100;
    }
    this.maxOneRep = maxRep;
    this.createNewWeightProgram(maxRep);
  }

  public createNewWeightProgram(maxRep: number) {
    localStorage.setItem(this.STORAGE_WEIGHT_KEY, JSON.stringify(maxRep));
    for (let i = 0; i < this.weeks; i++) {
      this.weightProgramRows[i] = new WeightProgramRow(i);
      this.weightProgramRows[i].weight1 = this.calculateWeight(
        this.weightProgramRows[i].days[0],
        maxRep
      );
      this.weightProgramRows[i].weight2 = this.calculateWeight(
        this.weightProgramRows[i].days[1],
        maxRep
      );
      this.weightProgramRows[i].repsInstructions1 = this.renderInstructions(
        this.weightProgramRows[i].days[0]
      );
      this.weightProgramRows[i].repsInstructions2 = this.renderInstructions(
        this.weightProgramRows[i].days[1]
      );
      // this.weightProgramRows[i].repsInstructions3 = this.renderInstructions(
      //   this.weightProgramRows[i].days[2]
      // );
    }

    console.log(this.weightProgramRows);
  }

  public calculateWeight(day: number, maxRepWeight: number): number {
    var max = maxRepWeight;
    var w = 0;
    if (
      day == 1 ||
      day == 2 ||
      day == 3 ||
      day == 4 ||
      day == 5 ||
      day == 6 ||
      day == 7 ||
      day == 8 ||
      day == 9 ||
      day == 11 ||
      day == 13 ||
      day == 15 ||
      day == 17
    )
      w = 0.8 * max;
    if (day == 10) w = 0.85 * max;
    if (day == 12) w = 0.9 * max;
    if (day == 14) w = 0.95 * max;
    if (day == 16) w = max;
    if (day == 18) w = 1.05 * max;
    return Math.round(w * Math.pow(10, 1)) / Math.pow(10, 1);
  }

  public renderInstructions(day: number): string {
    let instruction = "";
    if (day == 1) instruction = "6 x 2";
    if (day == 2) instruction = "6 x 3";

    if (day == 3) instruction = "6 x 2";
    if (day == 4) instruction = "6 x 4";

    if (day == 5) instruction = "6 x 2";
    if (day == 6) instruction = "6 x 5";

    if (day == 7) instruction = "6 x 2";
    if (day == 8) instruction = "6 x 6";

    if (day == 9) instruction = "6 x 2";
    if (day == 10) instruction = "5 x 5";

    if (day == 11) instruction = "6 x 2";
    if (day == 12) instruction = "4 x 4";

    if (day == 13) instruction = "6 x 2";
    if (day == 14) instruction = "3 x 3";

    if (day == 15) instruction = "6 x 2";
    if (day == 16) instruction = "2 x 2";

    if (day == 17) instruction = "6 x 2";
    if (day == 18) instruction = "New Max (105%)";

    return instruction;
  }
}

class WeightProgramRow {
  public weekText: string;
  public weekValue: number;
  public days: number[] = [2];
  public weight1: number;
  public weight2: number;
  public weight3: number;
  public repsInstructions1: string;
  public repsInstructions2: string;
  public repsInstructions3: string;
  public isNewMaxRep: boolean;

  constructor(_week: number) {
    this.weekValue = _week + 1;
    this.weekText = "Week " + this.weekValue;

    let dayValues = 2;
    this.days[0] = this.weekValue * dayValues - 1;
    this.days[1] = this.weekValue * dayValues - 0;

    this.isNewMaxRep = this.weekValue * dayValues - 0 == 18;
  }
}

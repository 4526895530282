import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import * as math from "mathjs";
import cities from "./cities.js";
import { PortfolioApiService } from "../../../portfolio-api.service";

@Component({
  selector: "app-neuralnetwork",
  templateUrl: "./neuralnetwork.component.html",
  styleUrls: ["./neuralnetwork.component.scss"],
})
export class NeuralnetworkComponent implements OnInit {
  @ViewChild("learning_status_bar", { static: true })
  learningStatusBar: ElementRef;

  activeElements = [];

  learningWorker = new Worker("../../../../assets/workers/learningWorker.js");

  location = "";
  latitude = 0;
  longitude = 0;
  normLatitude = 0;
  normLongitude = 0;

  inputNodes = 2;
  hiddenNodes = 10;
  outputNodes = 20;
  learningRate = 0.15;
  optimalIterationsCount = 15000;
  learningIterationsCount = 2500;
  iterationsLearned = 0;

  queryFinished = false;
  learningStatusPercentage = 0;
  neuralNetworkResultText = "";

  // Neuralnetwork related
  debugMode = false;
  wih = [];
  who = [];
  neuralNetworkInitialised = false;
  neuralNetworkIsTraining = false;
  hiddenInputs = [];
  hiddenOutputs = [];
  finalInputs = [];
  finalOutputs = [];
  outputErrors = [];
  hiddenErrors = [];
  finalErrors = [];
  outputNodesArray = [];

  minLatitude = 0;
  maxLatitude = 0;
  minLongitude = 0;
  maxLongitude = 0;

  cities;
  cityElements;
  formattedCityTrainingData;
  cityTrainingData;

  dataFetched = false;

  constructor(private portfolioApiService: PortfolioApiService) {}

  ngOnInit() {
    this.learningWorker.addEventListener("message", (event) => {
      this.neuralNetworkIsTraining = false;
      this.wih = event.data.wih;
      this.who = event.data.who;
      this.iterationsLearned += event.data.iterationsLearned;
      this.calculateLearningStatusPercentage();
    });

    this.learningStatusBar.nativeElement.style.background = "red";

    this.cities = cities[0];
    this.cityTrainingData = cities[1];

    this.initialiseCityPanelStyles();
    this.initialise(2, 10, 20);
    this.formatData();
    this.calculateLearningStatusPercentage();
    this.dataFetched = true;
  }
  // Initialise Neuralnetwork
  initialise(input_nodes, hidden_nodes, output_nodes) {
    this.wih = new Array(hidden_nodes);
    this.who = new Array(hidden_nodes);

    for (var i = 0; i < hidden_nodes; i++) {
      this.wih[i] = new Array(input_nodes);

      for (var j = 0; j < input_nodes; j++) {
        if (!this.debugMode) {
          this.wih[i][j] = this.getRandomWeight(-0.5, 0.5);
        } else {
          if (i == 0) {
            this.wih[i][j] = 2;
            this.who[i][j] = 2;
          } else if (i == 1) {
            this.wih[i][j] = 4;
            this.who[i][j] = 4;
          } else {
            this.wih[i][j] = 8;
            this.who[i][j] = 8;
          }
        }
      }
    }

    for (var i = 0; i < output_nodes; i++) {
      this.who[i] = new Array(hidden_nodes);

      for (var j = 0; j < hidden_nodes; j++) {
        if (!this.debugMode) {
          this.who[i][j] = this.getRandomWeight(-0.5, 0.5);
        } else {
          if (i == 0) {
            this.wih[i][j] = 2;
            this.who[i][j] = 2;
          } else if (i == 1) {
            this.wih[i][j] = 4;
            this.who[i][j] = 4;
          } else {
            this.wih[i][j] = 8;
            this.who[i][j] = 8;
          }
        }
      }
    }
    this.neuralNetworkInitialised = true;
  }

  _teachNeuralNetworkFully() {
    let neuralnetworkData = {
      outputNodes: this.outputNodes,
      iterationsLearned: this.learningIterationsCount,
      learningRate: this.learningRate,
      formattedCityTrainingData: this.formattedCityTrainingData,
      wih: this.wih,
      who: this.who,
    };
    this.neuralNetworkIsTraining = true;
    this.learningWorker.postMessage(neuralnetworkData);
  }

  formatData() {
    this.cities.sort((a, b) => {
      return a.originLatitude - b.originLatitude;
    });

    this.minLatitude = this.cities[0].originLatitude;
    this.maxLatitude = this.cities[this.cities.length - 1].originLatitude;

    this.cities.sort((a, b) => {
      return a.originLongitude - b.originLongitude;
    });

    this.minLongitude = this.cities[0].originLongitude;
    this.maxLongitude = this.cities[this.cities.length - 1].originLongitude;

    let formattedCityTrainingData = [];

    for (let i = 0; i < this.cities.length; i++) {
      formattedCityTrainingData.push([
        this.cityTrainingData[i].cityId - 1,
        [
          this.normaliseValue(
            this.cityTrainingData[i].latitude,
            this.maxLatitude + 0.05,
            this.minLatitude - 0.05,
            0.5,
            -0.5
          ),
          this.normaliseValue(
            this.cityTrainingData[i].longitude,
            this.maxLongitude + 0.05,
            this.minLongitude - 0.05,
            0.5,
            -0.5
          ),
        ],
      ]);
    }
    this.formattedCityTrainingData = formattedCityTrainingData;
  }

  // Function that makes a query with user input value from the network
  query() {
    (this.normLatitude = this.normaliseValue(
      this.latitude,
      this.maxLatitude + 0.05,
      this.minLatitude - 0.05,
      0.5,
      -0.5
    )),
      (this.normLongitude = this.normaliseValue(
        this.longitude,
        this.maxLongitude + 0.05,
        this.minLongitude - 0.05,
        0.5,
        -0.5
      ));

    this.hiddenInputs = math.multiply(
      math.matrix(this.wih),
      math.matrix([this.normLatitude, this.normLongitude])
    );

    this.hiddenOutputs = this.hiddenInputs["_data"].map((i) => {
      return (i = this.sigmoid(i));
    });

    this.finalInputs = math.multiply(
      math.matrix(this.who),
      math.matrix(this.hiddenOutputs)
    );

    this.finalOutputs = this.finalInputs["_data"].map((i) => {
      return (i = this.sigmoid(i));
    });

    this.queryFinished = true;
    let correctCityIndex =
      this.finalOutputs.indexOf(Math.max(...this.finalOutputs)) + 1;

    for (let i = 0; i < this.cities.length; i++) {
      if (correctCityIndex == this.cities[i].cityId) {
        this.neuralNetworkResultText = this.cities[i].name;
      }
    }
  }

  _neuralNetworkQueryHandler(value) {
    if (this.neuralNetworkInitialised) {
      this.cityPanelClickHandler(value);
      this.query();
    }
  }

  _neuralNetworkResetHandler() {
    this.initialise(2, 10, 20);
    this.iterationsLearned = 0;
  }

  initialiseCityPanelStyles() {
    this.cityElements = new Array(this.cities.length / 4);

    let index = 0;
    for (let x = 0; x < this.cityElements.length; x++) {
      this.cityElements[x] = new Array(4);
      for (let y = 0; y < 4; y++) {
        this.cityElements[x][y] = {
          id: this.cities[index].cityId,
          name: this.cities[index].name,
          lat: this.cities[index].originLatitude,
          lng: this.cities[index].originLongitude,
        };
        index++;
      }
    }

    for (let i = 0; i < this.cities.length * this.cities.length; i++) {
      this.activeElements[i] = "col";
    }
  }

  cityPanelClickHandler(value) {
    for (let i = 0; i < this.activeElements.length; i++) {
      this.activeElements[i] = "col";
    }

    for (let i = 0; i < this.activeElements.length; i++) {
      if (i == value) {
        this.activeElements[i] = "col active";
      }
    }
    for (let x = 0; x < this.cityElements.length; x++) {
      for (let y = 0; y < this.cityElements[x].length; y++) {
        if (value == this.cityElements[x][y].id) {
          this.location = this.cityElements[x][y].name;
          this.latitude = this.cityElements[x][y].lat;
          this.longitude = this.cityElements[x][y].lng;
        }
      }
    }
  }
  // Random value between 2 values
  getRandomWeight(min, max) {
    return Math.random() * (max - min) + min; //The maximum is inclusive and the minimum is inclusive
  }

  // Sigmoid
  sigmoid(z) {
    return 1 / (1 + Math.exp(-z));
  }

  normaliseValue(inputValue, maxValue, minValue, maxRange, minRange) {
    return (
      ((inputValue - (minValue - 0.01)) * (maxRange - minRange)) /
        (maxValue - 0.01 - (minValue - 0.01)) +
      minRange
    );
  }

  calculateLearningStatusPercentage() {
    this.learningStatusPercentage = Math.round(
      (this.iterationsLearned / this.optimalIterationsCount) * 100
    );
    this.learningStatusBar.nativeElement.style.width =
      this.learningStatusPercentage + "%";

    if (this.learningStatusPercentage < 25) {
      this.learningStatusBar.nativeElement.style.background = "#9a0000";
    } else if (this.learningStatusPercentage < 50) {
      this.learningStatusBar.nativeElement.style.background = "#9a4000";
    } else if (this.learningStatusPercentage < 75) {
      this.learningStatusBar.nativeElement.style.background = "#9a9a00";
    } else if (
      this.learningStatusPercentage > 75 &&
      this.learningStatusPercentage < 100
    ) {
      this.learningStatusBar.nativeElement.style.background = "rgb(6, 224, 6);";
    } else if (this.learningStatusPercentage >= 100) {
      this.learningStatusPercentage = 100;
      this.learningStatusBar.nativeElement.style.width = "100%";
      this.learningStatusBar.nativeElement.style.borderRadius = "0";
      this.learningStatusBar.nativeElement.style.background = "#52ab00";
    }
  }
}

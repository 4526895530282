import { Component, OnInit } from "@angular/core";
import { NavBarColorService } from "src/app/services/nav-bar-color.service";

@Component({
  selector: "app-project-root-handler",
  templateUrl: "./project-root-handler.component.html",
  styleUrls: ["./project-root-handler.component.scss"],
})
export class ProjectRootHandlerComponent implements OnInit {
  constructor(public navBarColorService: NavBarColorService) {}

  ngOnInit() {
    this.navBarColorService.userActiveInMainPage = false;
  }
}

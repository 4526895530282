import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { NavbarComponent } from "./components/navbar/navbar.component";
import { HeaderComponent } from "./components/header/header.component";

import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HttpClientModule } from "@angular/common/http";

import { FrontpageComponent } from "./components/frontpage/frontpage.component";
import { NeuralnetworkComponent } from "./components/projects/neuralnetwork/neuralnetwork.component";
import { MemegeneratorComponent } from "./components/projects/memegenerator/memegenerator.component";
import { MeditationAppComponent } from "./components/projects/meditation-app/meditation-app.component";
import { InterestsComponent } from "./components/frontpage-sections/interests/interests.component";
import { PortfolioComponent } from "./components/frontpage-sections/portfolio/portfolio.component";
import { AboutComponent } from "./components/frontpage-sections/about/about.component";
import { ContactComponent } from "./components/frontpage-sections/contact/contact.component";
import { FooterComponent } from "./components/frontpage-sections/footer/footer.component";

import { WINDOW_PROVIDERS } from "../app/components/core/window.service";
import { PortfolioApiService } from "./portfolio-api.service";
import { RussianStrengthProgramGeneratorComponent } from "./components/training/russian-strength-program-generator/russian-strength-program-generator.component";
import { ProjectRootHandlerComponent } from './components/projects/project-root-handler/project-root-handler.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderComponent,
    FrontpageComponent,
    NeuralnetworkComponent,
    MemegeneratorComponent,
    MeditationAppComponent,
    InterestsComponent,
    PortfolioComponent,
    AboutComponent,
    ContactComponent,
    FooterComponent,
    RussianStrengthProgramGeneratorComponent,
    ProjectRootHandlerComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
  ],
  providers: [WINDOW_PROVIDERS, PortfolioApiService],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div #header></div>
<nav [class]="navBarStyle">
  <div class="container-fluid">
    <!-- Brand and toggle get grouped for better mobile display -->
    <a
      class="navbar-brand"
      routerLink="{{ _ROUTES.ROOT }}"
      (click)="scrollToHome()"
    >
      <span class="navbar-brand-color">Portfolio</span><span> /..</span>
    </a>

    <button
      class="navbar-toggler"
      type="button"
      (click)="togglerOnClickHandler()"
      aria-expanded="false"
    >
      <div [class]="navBarTogglerStyle">
        <span></span><span></span><span></span>
      </div>
    </button>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse" [collapse]="isCollapsed">
      <ul class="nav navbar-nav ml-auto">
        <li
          [class]="
            navBarColorService.userActiveInMainPage
              ? 'dropdown nav-item nav-link'
              : 'dropdown nav-item nav-link-inverse'
          "
          dropdown
        >
          <a dropdownToggle class="dropdown-toggle"
            >Projects<span class="caret"></span
          ></a>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a
                routerLink="{{ _ROUTES.NEURAL_NETWORK }}"
                class="dropdown-item"
                href="#"
                >Neural Network</a
              >
            </li>
            <li role="menuitem">
              <a
                routerLink="{{ _ROUTES.MEME_GENERATOR }}"
                class="dropdown-item"
                href="#"
                >Meme Generator</a
              >
            </li>
            <li role="menuitem">
              <a
                routerLink="{{ _ROUTES.MEDITATION_APP }}"
                class="dropdown-item"
                href="#"
                >Android Meditation App</a
              >
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            routerLink="{{ _ROUTES.STRENGTH_TRAINING }}"
            [class]="
              navBarColorService.userActiveInMainPage
                ? 'nav-link'
                : 'nav-link-inverse'
            "
            >Strength Training</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

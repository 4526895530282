const cities = [
  [
    {
      cityId: 1,
      name: "Helsinki",
      originLatitude: 60.1695213,
      originLongitude: 24.9354496,
    },
    {
      cityId: 2,
      name: "Espoo",
      originLatitude: 60.2052002,
      originLongitude: 24.6522007,
    },
    {
      cityId: 3,
      name: "Tampere",
      originLatitude: 61.4991112,
      originLongitude: 23.7871208,
    },
    {
      cityId: 4,
      name: "Kajaani",
      originLatitude: 64.222176,
      originLongitude: 27.72785,
    },
    {
      cityId: 5,
      name: "Turku",
      originLatitude: 60.4514809,
      originLongitude: 22.2686901,
    },
    {
      cityId: 6,
      name: "Oulu",
      originLatitude: 65.0123596,
      originLongitude: 25.4681606,
    },
    {
      cityId: 7,
      name: "Lahti",
      originLatitude: 60.9826698,
      originLongitude: 25.6615105,
    },
    {
      cityId: 8,
      name: "Kuopio",
      originLatitude: 62.8923798,
      originLongitude: 27.6770306,
    },
    {
      cityId: 9,
      name: "Jyväskylä",
      originLatitude: 62.2414703,
      originLongitude: 25.7208805,
    },
    {
      cityId: 10,
      name: "Pori",
      originLatitude: 61.4833298,
      originLongitude: 21.7833309,
    },
    {
      cityId: 11,
      name: "Lappeenranta",
      originLatitude: 61.0587082,
      originLongitude: 28.1887093,
    },
    {
      cityId: 12,
      name: "Vaasa",
      originLatitude: 63.0960007,
      originLongitude: 21.6157703,
    },
    {
      cityId: 13,
      name: "Kotka",
      originLatitude: 60.4664001,
      originLongitude: 26.9458199,
    },
    {
      cityId: 14,
      name: "Joensuu",
      originLatitude: 62.601181,
      originLongitude: 29.7631607,
    },
    {
      cityId: 15,
      name: "Hämeenlinna",
      originLatitude: 60.9959602,
      originLongitude: 24.4643402,
    },
    {
      cityId: 16,
      name: "Porvoo",
      originLatitude: 60.3923302,
      originLongitude: 25.6650696,
    },
    {
      cityId: 17,
      name: "Mikkeli",
      originLatitude: 61.6885681,
      originLongitude: 27.2722702,
    },
    {
      cityId: 18,
      name: "Kemi",
      originLatitude: 65.579287,
      originLongitude: 24.196943,
    },
    {
      cityId: 19,
      name: "Kuusamo",
      originLatitude: 65.986503,
      originLongitude: 28.692848,
    },
    {
      cityId: 20,
      name: "Rauma",
      originLatitude: 61.1272392,
      originLongitude: 21.5112705,
    },
  ],
  [
    {
      cityId: 1,
      latitude: 60.1695213,
      longitude: 24.9354496,
    },
    {
      cityId: 2,
      latitude: 60.2052002,
      longitude: 24.6522007,
    },
    {
      cityId: 3,
      latitude: 61.4991112,
      longitude: 23.7871208,
    },
    {
      cityId: 4,
      latitude: 64.222176,
      longitude: 27.72785,
    },
    {
      cityId: 5,
      latitude: 60.4514809,
      longitude: 22.2686901,
    },
    {
      cityId: 6,
      latitude: 65.0123596,
      longitude: 25.4681606,
    },
    {
      cityId: 7,
      latitude: 60.9826698,
      longitude: 25.6615105,
    },
    {
      cityId: 8,
      latitude: 62.8923798,
      longitude: 27.6770306,
    },
    {
      cityId: 9,
      latitude: 62.2414703,
      longitude: 25.7208805,
    },
    {
      cityId: 10,
      latitude: 61.4833298,
      longitude: 21.7833309,
    },
    {
      cityId: 11,
      latitude: 61.0587082,
      longitude: 28.1887093,
    },
    {
      cityId: 12,
      latitude: 63.0960007,
      longitude: 21.6157703,
    },
    {
      cityId: 20,
      latitude: 61.1272392,
      longitude: 21.5112705,
    },
    {
      cityId: 13,
      latitude: 60.4664001,
      longitude: 26.9458199,
    },
    {
      cityId: 14,
      latitude: 62.601181,
      longitude: 29.7631607,
    },
    {
      cityId: 15,
      latitude: 60.9959602,
      longitude: 24.4643402,
    },
    {
      cityId: 16,
      latitude: 60.3923302,
      longitude: 25.6650696,
    },
    {
      cityId: 17,
      latitude: 61.6885681,
      longitude: 27.2722702,
    },
    {
      cityId: 18,
      latitude: 65.579287,
      longitude: 24.196943,
    },
    {
      cityId: 19,
      latitude: 65.986503,
      longitude: 28.692848,
    },
  ],
];

export default cities;

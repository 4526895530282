import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  about_me_list = [
    {
      icon: "icon-index-one programming-section-icon fa fa-university fa-2x",
      title: "Tampere University of Applied Sciences",
      work_title: "Software Engineer",
      work_date: "Graduated 2016",
      has_description: false,
      description: "",
    },
    {
      icon: "icon-index-two programming-section-icon fa fa-industry fa-2x",
      title: "Powerplant Load Management System",
      work_title: "Design Engineer",
      work_date: "2017 - 2018",
      has_description: true,
      description:
        "Work included using MSSQL and TSQL technologies to develop powerplant's local systems which comprised of automation, DCS & truck terminal user interfaces.",
    },
    {
      icon: "icon-index-thr programming-section-icon fa fa-industry fa-2x",
      title: "Customer Relationship Management System",
      work_title: "Software Specialist",
      work_date: "2018 - 2021",
      has_description: true,
      description:
        "I work as a front and backend developer in a Finnish company in the heart of Tampere, developing a CRM system using programming languages and libraries such as Js, Jquery, Perl, Css, Bootstrap and React.",
    },
    {
      icon: "icon-index-fou programming-section-icon fa fa-industry fa-2x",
      title: "Software Developer Consultant",
      work_title: "Fullstack Developer",
      work_date: "2021 - Currently Working",
      has_description: true,
      description:
        "Working as a consultant for a Finnish company in Tampere. Working currently for customer as frontend developer.",
    },
  ];

  constructor() {}

  ngOnInit() {}
}

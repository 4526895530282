<!-- start section header -->
<header class="masthead">
  <div class="container-fluid">
    <div class="header-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="center-header">
            <h1>Fullstack Developer & Software Enthusiast</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="header-background">
            <h5>-Teemu Kanerva</h5>
            <h6>- Interested of creating software that matters.</h6>
          </div>
        </div>
      </div>
      <div class="row typewriter-container">
        <div class="cl-sm-12">
          <div [class]="enableArrow ? 'typewriter hide' : 'typewriter show'">
            <h3 class="typewriter-text">cd /etc/portfolio_1.0/demo</h3>
          </div>
          <div
            [class]="
              enableArrow
                ? 'arrow-btn-container show'
                : 'arrow-btn-container hide'
            "
          >
            <div class="arrow-btn heartbeat" (click)="clickArrow()"></div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="row made-with-container">
          <div *ngFor="let item of madewith_list">
            <img
              class="made-with-icon mx-auto d-block"
              src="{{ item.url }}"
              on-mouseover="showMadeWithNameById(item.id)"
              on-mouseleave="hideMadeWithName()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- end section header -->

<app-project-root-handler></app-project-root-handler>
<div class="memegenerator-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 header-content">
        <h3>Meme Generator</h3>
      </div>
    </div>
    <div #memegenContainer class="row">
      <div class="col-md-8">
        <h5 *ngIf="!viewLoaded" class="canvas-guidance">
          Drag And Drop Or Upload image
        </h5>
        <canvas
          #canvas
          (mouseup)="_onMouseUp($event)"
          (mousedown)="_onMouseDown($event)"
          (mousemove)="_onMouseMove($event)"
          (drop)="_fileHandlerDrop($event)"
          (dragenter)="_dragOver($event)"
          (dragover)="_dragOver($event)"
          (dragleave)="_dragOver($event)"
          width="1024"
          height="1024"
        >
          Canvas not supported on user's browser.>
        </canvas>
      </div>
      <div class="col-md-4 memegen-editor">
        <div>
          <p>
            Tip! You can move the texts on screen by pressing on them and
            dragging them on the canvas element.
          </p>
        </div>
        <div class="form-group">
          <label>Upper text</label>
          <input
            class="form-control text-input"
            type="text"
            placeholder="Upper text"
            (input)="texts[0].value = $event.target.value"
            (keyup)="renderCanvas()"
            (change)="_textSizeHandler()"
          />
          <label>Lower text</label>
          <input
            class="form-control text-input"
            type="text"
            placeholder="lower text"
            (input)="texts[1].value = $event.target.value"
            (keyup)="renderCanvas()"
            (change)="_textSizeHandler()"
          />
        </div>
        <div class="form-group">
          <label>Upper text size</label>
          <input
            type="range"
            max="150"
            min="50"
            step="1"
            (input)="_upperFontHandler($event.target.value)"
          />
          <label>Lower text size</label>
          <input
            type="range"
            max="150"
            min="50"
            step="1"
            (input)="_lowerFontHandler($event.target.value)"
          />
        </div>
        <div class="form-group">
          <label>Background scale</label>
          <input
            type="range"
            max="5"
            min="0.1"
            step="0.01"
            (input)="_backGroundScaleHandler($event.target.value)"
          />
          <!-- <label>Background x position</label>
            <input type="range" max="400" min="-400" step="0.01" (input)="_backGroundXPosition($event.target.value)"/>
            <label>Background y position</label>
            <input type="range" max="400" min="-400" step="0.01" (input)="_backGroundYPosition($event.target.value)"/> -->
          <!-- <label>Background rotation</label>
            <input type="range" max="180" min="-180" step="1" (input)="_backGroundRotation($event.target.value)"/> -->
        </div>
        <div class="form-group">
          <button class="btn btn-danger" (click)="_clearCanvas($event)">
            <i class="fa fa-trash" aria-hidden="true"></i> Clear canvas
          </button>
        </div>
      </div>
    </div>
    <div class="row lower-button-section">
      <div class="col-md-8">
        <label for="file-upload" class="btn btn-primary">
          <i class="fa fa-file" aria-hidden="true"></i>
          Background
        </label>
        <input
          id="file-upload"
          class="display-none"
          type="file"
          name="file"
          (change)="_fileHandler($event)"
        />

        <button class="btn btn-primary" (click)="_centerTextHandler($event)">
          <i class="fa fa-align-center" aria-hidden="true"></i> Center text
        </button>
        <button class="btn btn-info" (click)="_downloadHandler($event)">
          <i class="fa fa-download" aria-hidden="true"></i> Download
        </button>
      </div>
    </div>
  </div>
  <img #img class="display-none" alt="" />
</div>
<app-footer></app-footer>

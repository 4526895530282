import { Component, ElementRef, ViewChild } from "@angular/core";
import { ElementRefHandlerService } from "src/app/services/element-ref-handler.service";
import { NavBarColorService } from "src/app/services/nav-bar-color.service";
import MainPageSubViews from "src/app/static/app-sub-views";

@Component({
  selector: "app-frontpage",
  templateUrl: "./frontpage.component.html",
  styleUrls: ["./frontpage.component.scss"],
})
export class FrontpageComponent {
  @ViewChild("interests") public interestsElementRef: ElementRef;

  constructor(
    public elementRefHandlerService: ElementRefHandlerService,
    public navBarColorService: NavBarColorService
  ) {}

  ngOnInit() {
    this.navBarColorService.userActiveInMainPage = true;
  }

  ngAfterViewInit() {
    this.elementRefHandlerService.interestsElementRef =
      this.interestsElementRef;
  }

  public scrollToView(viewSection: MainPageSubViews): void {
    this.elementRefHandlerService.moveToSpecificView(viewSection);
  }
}

<div class="russian-strength-program-generator-container">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 header-content">
        <h3>Russian Strength Program Calculator</h3>
        <div class="russian-strength-program-generator-description">
          <p>
            You can use this program generator to help design your squatting or
            pressing (i.e. strength exercises) workout routines. The resulting
            exercise program is intended to increase strength, not mass. This
            should not be used as your only squatting or pressing program over
            an extended period of time. This is intended as a means of inserting
            some variation into your programming and should not be used as a
            program mainstay.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h5><span>Your Current 1 MR</span></h5>
        <input
          type="text"
          class="form-control col-sm-2 offset-sm-5"
          placeholder="Insert your 1 RM..."
          (keyup)="createNewWeightProgram($event.target.value)"
          (change)="createNewWeightProgram($event.target.value)"
          value="{{ maxOneRep }}"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="neuralnetwork-data-container">
          <table>
            <thead>
              <tr>
                <th class="week-text-color"></th>
                <th class="week-text-color">Day 1 / reps</th>
                <th class="week-text-color">Weight</th>
                <th class="week-text-color">Day 3 / reps</th>
                <th class="week-text-color">Weight</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let weekProgram of weightProgramRows">
                <th style="width: 2%" class="week-text-color">
                  {{ weekProgram.weekText }}
                </th>
                <th style="width: 2%">{{ weekProgram.repsInstructions1 }}</th>
                <th style="width: 2%">{{ weekProgram.weight1 }}</th>
                <th style="width: 5%">{{ weekProgram.repsInstructions2 }}</th>
                <th style="width: 2%" *ngIf="weekProgram.isNewMaxRep">
                  <span>{{ weekProgram.weight2 }}</span>
                </th>
                <th style="width: 2%" *ngIf="!weekProgram.isNewMaxRep">
                  {{ weekProgram.weight2 }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { ElementRef, Injectable } from "@angular/core";
import MainPageSubViews from "../static/app-sub-views";

@Injectable({
  providedIn: "root",
})
export class ElementRefHandlerService {
  public headerElementRef: ElementRef;
  public interestsElementRef: ElementRef;

  constructor() {}

  public moveToSpecificView(viewSection: MainPageSubViews): void {
    let elementRef;
    let scrollBehaviour;

    switch (viewSection) {
      case MainPageSubViews.HEADER:
        elementRef = this.headerElementRef;
        scrollBehaviour = {
          behavior: "smooth",
          block: "end",
          inline: "start",
        };
        break;
      case MainPageSubViews.INTERESTS:
        elementRef = this.interestsElementRef;
        scrollBehaviour = {
          behavior: "smooth",
          block: "start",
          inline: "end",
        };
        break;
      case MainPageSubViews.PORTFOLIO:
        elementRef = this.interestsElementRef;
        break;
      case MainPageSubViews.ABOUT_ME:
        elementRef = this.interestsElementRef;
        break;
      case MainPageSubViews.CONTACT:
        elementRef = this.interestsElementRef;
        break;
      default:
      case MainPageSubViews.HEADER:
        elementRef = this.headerElementRef;
        break;
    }

    setTimeout(() => {
      elementRef.nativeElement.scrollIntoView(scrollBehaviour);
    });
  }
}

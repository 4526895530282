import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-interests",
  templateUrl: "./interests.component.html",
  styleUrls: ["./interests.component.scss"],
})
export class InterestsComponent implements OnInit {
  interests = [
    {
      icon: "programming-section-icon fa fa-code fa-4x",
      title: "Programming",
      description: "JS, Typescript, C#, Java, Perl, Php, SQL, CSS",
    },
    {
      icon: "algorithm-section-icon fa fa-cube fa-4x",
      title: "Algorithms",
      description: "Neural network, Pathfinding, Recursion",
    },
    {
      icon: "web-developement-icon fa fa-html5 fa-4x",
      title: "Web Development",
      description:
        "Angular, React, Nodejs, .Net, Redux, Bootstrap, REST, PostgreSQL",
    },
    {
      icon: "platform-icon fa fa-desktop fa-4x",
      title: "Infrastructure",
      description: "AWS, Docker & CI-pipeline",
    },
  ];

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from "@angular/core";
import { PortfolioApiService } from "src/app/portfolio-api.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
  name = "";
  contactEmail = "";
  message = "";
  sendingEmail = false;
  buttonStyle = "ml-auto mr-auto btn btn-primary";
  isEmailSent = false;

  constructor(private portfolioApiService: PortfolioApiService) {}

  ngOnInit() {}

  _submitContactEmail() {
    if (this.sendingEmail) {
      return;
    }

    if (
      this.name.length == 0 ||
      this.message.length == 0 ||
      this.contactEmail.length == 0
    ) {
      return;
    }

    this.sendingEmail = true;

    let contactMessage = {
      name: this.name,
      contactEmail: this.contactEmail,
      message: this.message,
    };

    this.portfolioApiService
      .sendContactEmail(contactMessage)
      .subscribe((result) => {
        this.sendingEmail = false;
        this.name = "";
        this.contactEmail = "";
        this.message = "";
        this.isEmailSent = true;

        setTimeout(() => {
          this.isEmailSent = false;
        }, 10000);
      }),
      (error) => {
        this.sendingEmail = false;
        this.name = "";
        this.contactEmail = "";
        this.message = "";
      };
  }
}

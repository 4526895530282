<app-project-root-handler></app-project-root-handler>
<div class="neuralnetwork-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 header-content">
        <h3>Neuralnetwork</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 city_grid">
        <h5>Select City</h5>
        <div *ngIf="dataFetched">
          <div *ngFor="let cityGroup of cityElements">
            <div class="row">
              <a
                *ngFor="let city of cityGroup"
                class="{{ activeElements[city.id] }}"
                (click)="_neuralNetworkQueryHandler(city.id)"
                >{{ city.name }}</a
              >
            </div>
          </div>
        </div>
        <div class="neuralnetwork-result-container">
          <div *ngIf="queryFinished">
            <label
              >Neuralnetwork predicts that the latitude and longitude belong
              to</label
            ><br />
            <span>{{ neuralNetworkResultText }}</span>
          </div>
          <div *ngIf="!queryFinished">
            <label
              >Click on a <span>city</span> and make a query to neuralnetwork to
              see results!</label
            >
          </div>
        </div>
      </div>
      <br />
      <div class="col-md-6">
        <h5 class="d-none d-sm-block">Learning status</h5>
        <div #learning_status_bar class="learning-container">
          {{ learningStatusPercentage }}%
        </div>
        <div class="neuralnetwork-data-container">
          <h5>Neuralnetwork data</h5>
          <table>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Location</th>
                <th>{{ location }}</th>
              </tr>
              <tr>
                <th>Latitude</th>
                <th>{{ latitude }}</th>
              </tr>
              <tr>
                <th>Longitude</th>
                <th>{{ longitude }}</th>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Field</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Input Nodes</th>
                <th>{{ inputNodes }}</th>
              </tr>
              <tr>
                <th>Hidden Nodes</th>
                <th>{{ hiddenNodes }}</th>
              </tr>
              <tr>
                <th>Output Nodes</th>
                <th>{{ outputNodes }}</th>
              </tr>
              <tr>
                <th>Learning Rate</th>
                <th>{{ learningRate }}</th>
              </tr>
              <tr>
                <th>Optimal Iterations</th>
                <th>{{ optimalIterationsCount }}</th>
              </tr>
              <tr>
                <th>Iterations Learned</th>
                <th>{{ iterationsLearned }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="neural-network-description">
          <hr />
          <div>
            <p>
              When neuralnetwork is initialised it doesnt know anything about
              the latitudes and longitudes. We have to <span>teach</span> it
              first.
            </p>
            <p>
              Try pressing the Teach neural neural network button to teach the
              neural network. The more learning iterations the neuralnetwork has
              gone through the more accurate the predictions are!
            </p>
          </div>
          <hr />
          <p>
            When query is sent to the neuralnetwork we give it latitude and
            longitude as parameters from the chosen city. For result we get the
            neural that has the biggest activation value. That neural index
            represents the city that the neuralnetwork predicted the latitude
            and longitude belongs to.
          </p>
          <hr />
        </div>
      </div>
      <div class="col-md-6 button-control-container">
        <div>
          <button class="btn btn-primary" (click)="_teachNeuralNetworkFully()">
            Teach NeuralNetwork {{ learningIterationsCount }} iterations
          </button>
          <button class="btn btn-danger" (click)="_neuralNetworkResetHandler()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<div class="loader-icon" *ngIf="neuralNetworkIsTraining">
  <div class="spinner">
    <div class="cube1"></div>
    <div class="cube2"></div>
  </div>
  <p>This might take awhile...</p>
</div>

<div class="container">
  <div class="row about-container">
    <div class="col-md-6">
      <div class="about-image-tre"></div>
    </div>
    <div class="col-md-6">
      <h2>About Me</h2>
      <div class="about-me-container">
        <p>
          Living in the city of Tampere in Finland my youth was mainly spent on
          computer games and computer related hobbies such as making music with
          trackers or configuring Quake config files.
        </p>
        <p>
          My first lines of code were written in secondary school’s computer
          sciences class when I was 14 years old. I had made a bat file that
          played notes in a sequence. It wasn’t the greatest application but it
          catched my interest in computer sciences.
        </p>
        <p>
          Eventually having tried different career paths from restaurant chef to
          welder, I ended up in Tampere University of Applied Sciences to pursue
          my dream career as a programmer. There I learned the basic building
          blocks of coding and got hooked by the complexity of what computer
          sciences could offer.
        </p>
        <p>
          After graduation I published my first application to Google Play
          Store, eventually finding myself working in a Finnish software
          company.
        </p>
      </div>
    </div>
  </div>
  <div class="row about-container">
    <div class="col-md-6 d-none d-md-block d-lg-block">
      <h2>Education & Work</h2>
      <div class="row" *ngFor="let item of about_me_list">
        <div class="col-md-2">
          <div class="icon-container">
            <i class="{{ item.icon }}" aria-hidden="true"></i>
          </div>
        </div>
        <div class="col-md-10">
          <strong
            ><p class="about-education-title">{{ item.title }}</p></strong
          >
          <p class="about-education-target">{{ item.work_title }}</p>
          <p class="about-education-target">{{ item.work_date }}</p>
          <div
            class="about-education-description-container"
            *ngIf="item.has_description"
          >
            <p class="about-education-description">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="about-image-tamk"></div>
    </div>
    <div class="col-md-6 d-md-none d-lg-none">
      <h2>Education & Work</h2>
      <div class="row" *ngFor="let item of about_me_list">
        <div class="col-md-2">
          <div class="icon-container">
            <i class="{{ item.icon }}" aria-hidden="true"></i>
          </div>
        </div>
        <div class="col-md-10">
          <strong
            ><p class="about-education-title">{{ item.title }}</p></strong
          >
          <p class="about-education-target">{{ item.work_title }}</p>
          <p class="about-education-target">{{ item.work_date }}</p>
          <div
            class="about-education-description-container"
            *ngIf="item.has_description"
          >
            <p class="about-education-description">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

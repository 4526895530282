<div class="container">
  <div class="">
    <div class="row">
      <div class="col-md-8 section-header">
        <h2 class="interests-title">Interests</h2>
        <p>
          I have always been interested in computers and computer programs from
          young age. What fascinates me the most in computer programs is the
          “magic” behind them and how they work and are made.
        </p>
      </div>
    </div>
    <div class="row">
      <div *ngFor="let item of interests" class="col-md-3">
        <div class="interest-section">
          <div class="row">
            <i class="{{ item.icon }}" aria-hidden="true"></i>
          </div>
          <div class="row">
            <h5>{{ item.title }}</h5>
          </div>
          <div class="row">
            <p class="interest-description">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-meditation-app",
  templateUrl: "./meditation-app.component.html",
  styleUrls: ["./meditation-app.component.scss"],
})
export class MeditationAppComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

<div class="mediation-app-container">
  <div class="container">
    <div class="row">
      <div class="col-md-12 header-content">
        <h3>Meditation App for Android</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>
          Freemind is a meditation app that makes meditation sessions easy to
          track.
        </p>

        <p>
          Users of Freemind app can easily change meditation time duration and
          alert intervals depending on user’s settings. Meditation settings are
          stored into the phone memory and it is easy for the user to meditate
          using previously stored preferences.
        </p>

        <p>
          Duration of meditation can be set between 5 minutes to 2 hours. Alert
          intervals can be set to periods between 2 to 30 minutes.
        </p>
        <p>
          User meditation sessions are stored in the phone memory and can be
          viewed in as bar chart graph. This helps the user to track meditation
          progress.
        </p>

        <i class="meditation-app-additional-information"
          >Meditation app was developed with Android Studio and has incorporated
          Google Ads and Firebase Analytics</i
        >
        <div class="row">
          <a
            href="https://play.google.com/store/apps/details?id=com.knr.freemind"
            target="_blank"
            ><img
              class="img-responisve googleplay-img"
              src="../../../../assets/googleplay.fi_ALL.png"
          /></a>
        </div>
      </div>
      <div class="col-md-6">
        <img
          class="img-responsive app-img image-portrait"
          src="../../../../assets/1.png"
        />
        <img
          class="img-responsive app-img image-portrait"
          src="../../../../assets/2.png"
        />
        <img
          class="img-responsive app-img image-portrait"
          src="../../../../assets/3.png"
        />
        <img
          class="img-responsive app-img image-landscape"
          src="../../../../assets/4.png"
        />
      </div>
    </div>
  </div>
</div>
> <app-footer></app-footer>
